import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util/util.service';
// import { KolektorPenagihanAddComponent } from '../kolektor-penagihan-add/kolektor-penagihan-add.component';
import { IzinPreviewComponent } from 'src/app/izin/izin-preview/izin-preview.component';
import { PreviewImageNotokenComponent } from 'src/app/shared/preview-image-notoken/preview-image-notoken.component';
import { DomSanitizer } from '@angular/platform-browser';
import { PreviewPhotoLaporanComponent } from 'src/app/shared/preview-photo-laporan/preview-photo-laporan.component';
import { MemorandumApproveRejectComponent } from '../../approval-memorandum/memorandum-approve-reject/memorandum-approve-reject.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-memorandum-app-detail',
  templateUrl: './memorandum-app-detail.component.html',
  styleUrls: ['./memorandum-app-detail.component.scss'],
})
export class MemorandumAppDetailComponent implements OnInit {

  load: boolean = false;
  saving: any = {};
  idData: any;

  detData: any = {};
  det: any;
  tampilData: boolean = false;

  cabang: any = '';
  grup: any = '';
  nama: any = '';
  nip: any = '';
  jabatan: any = '';
  phone: any = '';
  email: any = '';
  username: any = '';
  password: any = '';

  usertype: any = '';

  selct: any = '';


  disabledInput: boolean = false;
  detList: any = {};

  id: any;
  ids: any;

  form: any;
  amor: any;

  riwPem: any = {};
  rw: any;

  riwayat_collector: any;
  penagihan_tertunda: any;
  riwayat_pemby: any;

  lead_id: any = '';

  detail: any = {};
  // det: any = {};
  agunan: any = {};
  credit_checking: any = {};
  header: any = {};
  pasangan: any = {};
  pemohon: any = {};
  pengajuan: any = {};
  penjamin: any;
  kts: any = {};

  info: string = "Pemohon";
  foto: any = {};

  infox: string = "";

  fotoList: any;
  photoListOther: any;

  tampakDepan: any;
  tampakBelakang: any;
  tampakSamping1: any;
  tampakSamping2: any;

  tampakDepanx: any;
  tampakBelakangx: any;
  tampakSamping1x: any;
  tampakSamping2x: any;

  loadtampakDepan: boolean = false;
  loadtampakBelakang: boolean = false;
  loadtampakSamping1: boolean = false;
  loadtampakSamping2: boolean = false;

  listPhotoOtherx: any;
  photoOther1: any;
  photoOther2: any;
  photoOther3: any;
  photoOther4: any;
  photoOther5: any;
  photoOther6: any;

  photoOther1x: any = '';
  photoOther2x: any = '';
  photoOther3x: any = '';
  photoOther4x: any = '';
  photoOther5x: any = '';
  photoOther6x: any = '';

  loadphotoOther1: any;
  loadphotoOther2: any;
  loadphotoOther3: any;
  loadphotoOther4: any;
  loadphotoOther5: any;
  loadphotoOther6: any;

  tampakDepanxx: any;
  tampakBelakangxx: any;
  tampakSamping1xx: any;
  tampakSamping2xx: any;

  photoOther1xx: any;
  photoOther2xx: any;
  photoOther3xx: any;
  photoOther4xx: any;
  photoOther5xx: any;
  photoOther6xx: any;
  agunanx: any;
  fotoListx: any;

  kapasitas: any;

  credit_checkingSlik: any = {};
  credit_checkingPef: any = {};
  loaddownload1: boolean = false;
  token: any = '';
  baseUrl: any = '';

  oc: any = {};
  docIdentitas: any;
  docIdentitasx: any;
  docAgunan: any;
  docKapasitas: any;
  formx: any;

  adaDataAgunan: boolean = false;
  docAgunanCustom: any;
  docOtherIdentitas: any;
  docOtherAgunan: any;
  docx: any;
  allfile: any;
  fp: any;

  btnCompleteAgunan: boolean = false;
  tenors: any;
  jaminan: any;


  idMap: any = {};
  gabunganAgunan: any = {};
  gabunganAgunanx: any;

  docIdentitasCustom: any;

  docIdentitasOther: any;

  loading: boolean = false;
  usertoken: any;
  progress: any = 0;

  dataLocal: any = {};

  pemohonx: any = {};
  params: any = {};
  pengajuanx: any = {};
  scoring: any = {};
  scoring_point: any = {};

  pointpurp: any = {};
  pointChar: any = 0;

  dataLocalx: any = {};

  institusi_id: any = '';
  institusi_keuangan: any = '';
  institusi_point: any = '';

  dataChar: any;
  getPointChar: any;

  ttt: any = {};
  tt: any;
  listBillx: any;

  pointCap: any = 0;
  dataCapa: any = {};

  totPengeluaran: any = 0;
  angsuranTempatlain: any = 0;
  dataPengeluaran: any = {};

  maksAngusuranDiambil: any = 0;
  max_angs_diambil: any;

  idir: any = '0';
  dsr: any = '0';

  qq: number = 0;
  ww: number = 0;

  cps: any = {};
  cp: any;

  lamaKerja: any = '';
  statusKawin: any = '';
  tenor: any = '';

  pointCond: any = 0;
  pointColl: any = 0;

  scoring_cond: any;
  scoring_ag: any;
  lama_kerja: any;
  status_perkawinan: any;
  tenorx: any;
  coll_agunan: any;
  coll: any;

  totalHargaTaksasi: any = 0;
  totalLtv: any = 0;
  textMerah: boolean = false;
  pcl: any = {};
  pc: any = {};

  btnload: boolean = false;
  purp: any;

  dataProses: any
  proses_aset_id: any;
  proses_aset_point: any;
  index: any;
  pointCapId: any;

  rekom: any = {};
  rk: any = {};
  scoring_char: any;
  ringkasan: any = {};
  rekomendasi_surveyor: any = {};

  scoring_capa: any;


  flg_perubahan_akta: boolean = false;

  detMem: any = {};
  dm: any;

  credit_analyst: any = {};
  rrrrrrrrrrrrr: any;

  agunanCond: any;
  agunanColl: any;

  users: any;
  parent: any = {};
  children: any = {};
  action: any;

  act1: any;
  act2: any;
  act3: any;
  act4: any;

  kondar_checkingSlik: any;
  kondar_checkingPef: any;

  constructor(
    public api: ApiService,
    public util: UtilService,
    // public navParams: NavParams,
    public activatedRoute: ActivatedRoute,
    public modalController: ModalController,
    private dom: DomSanitizer,
    private platform: Platform,
    public http: HttpClient,
    public router: Router
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.no) {
        this.id = params.no;
        console.log('id', this.id)
        this.fp = params.fp;
        console.log('fp', this.fp)
        // this.fromPage = params.fromPage;
      }
    });
  }

  ngOnInit() {
    // this.riwayat();
    this.detailData();
    this.users = this.api.getUserMenu();
    this.addx()
  }

  addx(){
    try {
      this.parent = this.users.find((item: any) => item.menu_id === 'Credit Analis');
      this.children = this.parent.childrens.find((item: any) => item.id === 'Approval Memorandum');
      this.action = this.children.actions;
      console.log(this.users);
      console.log(this.children);
      console.log(this.action);

      this.act1 = this.action.find((item: any) => item === 'Proses');
      this.act2 = this.action.find((item: any) => item === 'Tolak');
      console.log(this.act1)
    } catch (error) {
      
    }
  }

  gabunganAgunanScoring() {
    var agunan = this.detList.agunan;
    var scoring_agx = this.detList.scoring.cond_agunan;
    var combinedArray = [];

    agunan.forEach(function (item1) {
      var item2 = scoring_agx.find(function (item) {
        return item.agunan_id === item1.id;
      });
      if (item2) {
        var combinedItem = Object.assign({}, item1, item2);
        combinedArray.push(combinedItem);
      }
    });
    this.agunanCond = combinedArray
    console.log('combinedArray', combinedArray);
  }

  gabunganAgunanColl() {
    var agunan = this.detList.agunan;
    var scoring_agx = this.detList.scoring.coll_agunan;
    var combinedArray = [];

    agunan.forEach(function (item1) {
      var item2 = scoring_agx.find(function (item) {
        return item.agunan_id === item1.id;
      });
      // var item3 = scoring_agx.find(function (item) {
      //   return item.agunan_id === item1.id;
      // });
      if (item2) {
        var combinedItem = Object.assign({}, item1, item2);
        combinedArray.push(combinedItem);
      }
    });
    this.agunanColl = combinedArray
    console.log('combinedArray', combinedArray);
  }

  async previewX(p: any,nama: any) {
    this.load = true;
    this.usertoken = localStorage.getItem('usertoken') || 'null';
    const url = p; // URL file PDF yang akan diunduh

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.usertoken // Menggunakan token sebagai header Authorization
    });

    // Periksa apakah aplikasi sedang berjalan di perangkat seluler atau desktop
    if (this.platform.is('mobile')) {
      this.load = false;
      // Jika aplikasi berjalan di perangkat seluler, gunakan window.open untuk membuka file PDF di browser sistem
      window.open(url, '_system');
    } else {
      // Jika aplikasi berjalan di desktop, gunakan HttpClient untuk mendownload file PDF
      this.http.get(url, { headers, responseType: 'blob' }).subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        this.load = false;

        window.open(url, '_blank');
      });
    }
  }


  detailMemoApp() {
    this.load = true;
    this.api.get('backoffice/credit-analyst/app-memo/' + this.id)
      .subscribe((data) => {
        this.detMem = data;
        if (this.detMem.error == 0) {
          this.load = false;
          if (this.detMem.data.total == 0) {
            this.dm = 'null';
          } else {
            this.dm = this.detMem.data.rows;
          }
        } else {
          this.load = false;
          this.util.showToast(this.detMem.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });

  }

  async prosess() {
    const modal = await this.modalController.create({
      component: MemorandumApproveRejectComponent,
      cssClass: 'modalForm',
      componentProps: {
        id: JSON.stringify(this.detList),
        st: 'ap'
      }
    });
    modal.onDidDismiss()
      .then((data: any) => {
        if (data.role === 'confirm') {
          this.router.navigate(['./approval-memorandum'])

        }
      });
    return await modal.present();
  }


  async tolak() {
    const modal = await this.modalController.create({
      component: MemorandumApproveRejectComponent,
      cssClass: 'modalForm',
      componentProps: {
        id: JSON.stringify(this.detList),
        st: 'rj'
      }
    });
    modal.onDidDismiss()
      .then((data: any) => {
        if (data.role === 'confirm') {
          this.router.navigate(['./approval-memorandum'])

        }
      });
    return await modal.present();
  }


  captureImageGlobal(data: any, ss: any) {

  }

  async rekomen() {
    // const modal = await this.modalController.create({
    //   component: MemorandumRekomendasiComponent,
    //   cssClass: 'modalForm',
    //   componentProps: {
    //     id: JSON.stringify(this.detList)
    //   }
    // });
    // modal.onDidDismiss()
    //   .then((data: any) => {
    //     if (data.role === 'confirm') {
    //     }
    //   });
    // return await modal.present();
  }

  pilih(p) {
    console.log(p);
    if (this.selct == '') {
      this.selct = p;
    } else if (this.selct == p) {
      // this.selct = '';
    } else {
      this.selct = p;
    }
    console.log('selct', this.selct);
  }

  getNamePhoto(path: any) {
    try {
      var photoName = path.substring(path.lastIndexOf("/") + 1, path.length);
      return photoName
    } catch (error) {
      console.log(error)
    }
  }

  async lihatI(id: any) {
    console.log(id)
    const modal = await this.modalController.create({
      component: PreviewImageNotokenComponent,
      cssClass: 'modalPreviewImage',
      componentProps: {
        id: id
      }
    });
    modal.onDidDismiss()
      .then((data: any) => {
        if (data.role === 'confirm') {
          // this.ionViewWillEnter();
        }
      });
    return await modal.present();
  }

  detailData() {
    this.load = true;

    // console.log('fp',this.fp)
    // if (this.fp == 'am') {
    //   var url = 'backoffice/credit-analyst/app-memo-list/'
    // }else{
      var url = 'backoffice/credit-analyst/app-memo-list/';
    // }
    this.api.get(url + this.id)
      .subscribe((data) => {
        this.detData = data;
        if (this.detData.error == 0) {
          this.load = false;
          this.detList = this.detData.data;
          this.det = this.detData.data;
          this.lead_id = this.detList.lead_id
          this.header = this.detList.header;
          this.credit_checking = this.detList.credit_checking;
          this.pengajuan = this.detList.pengajuan || '';
          this.pasangan = this.detList.pasangan;
          this.pemohon = this.detList.pemohon;
          this.agunan = this.detList.agunan;
          this.foto = this.detList.foto;
          this.penjamin = this.detList.penjamin;
          this.kapasitas = this.detList.kapasitas;
          this.fotoList = this.foto.agunan.main;
          this.fotoListx = this.foto.agunan.others;
          this.docIdentitasx = this.detList.dokumen.identitas;
          this.ringkasan = this.detList.ringkasan;
          this.rekomendasi_surveyor = this.ringkasan.rekomendasi.surveyor;
          this.credit_analyst = this.ringkasan.rekomendasi.credit_analyst;
          console.log('agunan', this.agunan);
          console.log('fotoList', this.fotoList);
          console.log('ringkasan', this.ringkasan);


          try {
            this.agunanx = this.agunan.map(dataItem => {
              let filteredArrayToAdd = this.fotoList.filter(item => item.ref_id_2 === dataItem.id);
              console.log(filteredArrayToAdd)
              if(this.fotoListx){
                this.rrrrrrrrrrrrr = this.fotoListx.filter(item => item.ref_id_2 === dataItem.id);
              }else{
                this.rrrrrrrrrrrrr = []
              }
              console.log(filteredArrayToAdd)
              return {
                ...dataItem,
                foto: filteredArrayToAdd,
                other: this.rrrrrrrrrrrrr
              };

            });
          } catch (error) {
            console.log('agunanx', error);
            // this.agunanx = this.agunan;
          }
          console.log('agunanx', this.agunanx);

          this.credit_checkingSlik = this.credit_checking.slik;
          this.credit_checkingPef = this.credit_checking.pefindo;

          if (this.credit_checkingSlik.length == 0) {
            this.credit_checkingSlik = '';
          }
          if (this.credit_checkingPef.length == 0) {
            this.credit_checkingPef = '';
          }

          if(this.credit_checkingSlik.penjamin){
            this.kondar_checkingSlik = this.credit_checkingSlik.penjamin
          }else{
            this.kondar_checkingSlik = 'null';
          }

          if(this.credit_checkingPef.penjamin){
            this.kondar_checkingPef = this.credit_checkingPef.penjamin;
          }else{
            this.kondar_checkingPef = 'null';
          }

          try {
            if (this.det.dokumen.others.doc_identitas) {
              this.docOtherIdentitas = [this.det.dokumen.others.doc_identitas];
            } else {
              this.docOtherIdentitas = '';
            }
          } catch (error) {
            this.docOtherIdentitas = '';
            console.log('docOtherIdentitas', error)
          }

          try {

            if (this.det.dokumen.others.doc_agunan) {
              this.docOtherAgunan = [this.det.dokumen.others.doc_agunan];
            } else {
              this.docOtherAgunan = '';
            }

          } catch (error) {
            this.docOtherAgunan = '';
            console.log('docOtherAgunan', error)
          }

          console.log('docOtherIdentitas', this.docOtherIdentitas);
          console.log('docOtherAgunan', this.docOtherAgunan);

          try {
            if (this.det.dokumen.others.doc_kapasitas) {
              var docKapasitas = this.det.dokumen.others.doc_kapasitas;
              this.docKapasitas = [docKapasitas];
            } else {
              this.docKapasitas = '';
            }
          } catch (error) {
            this.docKapasitas = '';
          }
          console.log('docKapasitas', this.docKapasitas);

          var identitas = this.docIdentitasx.filter((item: any) => item.name !== 'doc_identitas');
          this.docIdentitas = identitas;
          console.log('docIdentitas', this.docIdentitas);

          this.docAgunan = this.det.dokumen.agunan;
          try {
            var docAgunanCustom = this.det.dokumen.others.doc_agunan;
            this.docAgunanCustom = [docAgunanCustom];
          } catch (error) {
            this.docAgunanCustom = '';
          }
          console.log('docAgunanCustom', this.docAgunanCustom);

          if (this.det.penjamin.length == 0) {
            this.penjamin = '';
          } else {
            this.penjamin = this.det.penjamin;
          }

          console.log('penjamin', this.penjamin);
          this.kts = this.det.kts;
          this.foto = this.det.foto;
          this.tenors = Object.values(this.pengajuan.product.tenors);
          this.jaminan = Object.values(this.pengajuan.product.certificates_name);

          this.credit_checkingSlik = this.credit_checking.slik;
          this.credit_checkingPef = this.credit_checking.pefindo;

          if (this.credit_checkingSlik.length == 0) {
            this.credit_checkingSlik = '';
          }
          if (this.credit_checkingPef.length == 0) {
            this.credit_checkingPef = '';
          }
          this.gabunganAgunanScoring();
          this.gabunganAgunanColl();

          this.mergingData();
          // this.complete = this.hasil.data.complete;
          // this.enable = this.hasil.data.enable;
          this.load = false;

          if (this.agunan.length == 0) {
            this.btnCompleteAgunan = false;
            this.adaDataAgunan = false;
          } else {
            this.adaDataAgunan = true;
            var cariFlagComplete = this.agunan.find((item: any) => item.flg_completed === false);
            try {
              var rr = [cariFlagComplete];
              var tt = rr[0].id;
              console.log('rr', rr)
              console.log('tt', tt)
              this.btnCompleteAgunan = false;
            } catch (error) {
              this.btnCompleteAgunan = true;
              console.log('rr', 'KOSONG')
            }
          }
          this.dataLocal = this.detList;
          console.log('dataLocal', this.dataLocal)
          this.pemohonx = this.dataLocal.pemohon;
          this.pointpurp = this.dataLocal.scoring.purp[0];
          this.dataCapa = this.dataLocal.scoring.capa[0];
          this.dataChar = this.dataLocal.scoring.char[0];
          this.params = this.dataLocal.params;
          this.pengajuan = this.dataLocal.pengajuan;
          this.scoring = this.dataLocal.scoring;
          this.scoring_point = this.dataLocal.scoring_point;

          this.scoring_cond = this.scoring?.cond;
          this.scoring_ag = this.scoring?.cond_agunan;
          this.scoring_char = this.scoring?.char;
          this.scoring_capa = this.scoring?.capa;

          this.pointChar = this.dataChar?.point;
          this.pointCap = this.dataCapa?.point;

          try {
            this.max_angs_diambil = this.params.max_angs_diambil || '';
          } catch (error) {
            this.max_angs_diambil = '';
            console.log(error)
          }
          this.coll_agunan = this.scoring.coll_agunan;
          this.purp = this.scoring.purp;
          this.coll = this.scoring.coll;
          this.load = false;

          console.log('this.coll', this.coll)
          try {
            this.lama_kerja = this.scoring_cond.find((item: any) => item.group === 'lama_kerja');
            this.lamaKerja = this.lama_kerja.value;
            console.log('lamaKerja', this.lamaKerja)
          } catch (error) {
            console.log('lama_kerja', error)
            this.lamaKerja = '';
          }

          try {
            this.status_perkawinan = this.scoring_cond.find((item: any) => item.group === 'status_perkawinan');
            this.statusKawin = this.status_perkawinan.value;
          } catch (error) {
            this.statusKawin = '';
          }

          try {
            this.tenorx = this.scoring_cond.find((item: any) => item.group === 'tenor');
            this.tenor = this.tenorx.value;
          } catch (error) {
            this.tenor = '';
          }

          this.getDataChar();

          // try {
          //   this.getPengeluaran();
          // } catch (error) {
          //   console.log('getPengeluaran', error)
          // }

          try {
            this.scoring = this.dataLocal.scoring;
            this.scoring_point = this.dataLocal.scoring_point;
            this.scoring_cond = this.scoring.cond;
            this.coll_agunan = this.scoring.coll_agunan;
            this.kalkulasiTaksasi()
          } catch (error) {
            console.log('coll', error)
          }
          this.kalkulasiAgunan();
          if (this.pengajuan.ltv_value !== '') {
            this.totalLtv = this.pengajuan.ltv_value;
          }
          this.getEditPengeluaran();
        } else {
          this.load = false;
          this.util.showToast(this.detData.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  getDataChar() {
    try {
      if (this.scoring_char.length !== 0) {
        localStorage.setItem('674e87yuh4e87ygh4fe87yjh4ef8yuh4yuasdasdhj', JSON.stringify(this.scoring_char));
        this.dataChar = JSON.parse(localStorage.getItem('674e87yuh4e87ygh4fe87yjh4ef8yuh4yuasdasdhj') || '');
        this.kalkulasi();
      }
    } catch (error) {
      console.log('dataChar', error)
    }
  }

  kalkulasi() {
    // this.pointChar = this.dataChar.reduce((acc, o) => acc + parseFloat(o.point), 0);
    // this.updateLsChar();
  }

  updateLsChar() {
    // UPDATE LOCALSTORAGE
    this.dataProses = JSON.parse(localStorage.getItem('87gr3u4g37gf37yf34yf36y74f37437y4fghshdjf') || '');
    this.scoring = this.dataProses.scoring;
    let ax = localStorage.getItem('674e87yuh4e87ygh4fe87yjh4ef8yuh4yuasdasdhj') || '';
    this.dataChar = JSON.parse(ax);
    // Char
    this.dataProses.scoring.char = this.dataChar;
    console.log('dataProses', this.dataProses)
    localStorage.setItem('87gr3u4g37gf37yf34yf36y74f37437y4fghshdjf', JSON.stringify(this.dataProses));
  }

  getPengeluaran() {
    try {

      try {
        this.dataPengeluaran = JSON.parse(localStorage.getItem('5643ter34vt34t34t34rsdre4tse5tv45egv54t4') || '');
      } catch (error) {
        this.dataPengeluaran = '';
      }
      console.log('dataPengeluaran', this.dataPengeluaran)

      if (this.dataPengeluaran !== '') {
        this.totPengeluaran = this.dataPengeluaran.totalPengeluaran;
        console.log('totPengeluaran', this.totPengeluaran)
        this.angsuranTempatlain = this.dataPengeluaran.angsuranTempatLain;
        // =(Total Penghasilan*max_angs_diambil%)-total pengeluaran
        try {
          this.maksAngusuranDiambil = (this.dataCapa.total_penghasilan * this.params.max_angs_diambil / 100) - this.totPengeluaran || '-';

        } catch (error) {

        }
        // =((Angsuran Tempat Lain + Angsuran Diambil)/Total Penghasilan)*100
        this.qq = ((parseInt(this.angsuranTempatlain) + parseInt(this.dataCapa.angs_diambil)) / this.dataCapa.total_penghasilan) * 100 || 0;
        this.dsr = this.qq.toFixed(2);
        // =((Angsuran diambil+total pengeluaran)/total penghasilan)*100;
        this.ww = ((parseInt(this.dataCapa.angs_diambil) + parseInt(this.totPengeluaran)) / this.dataCapa.total_penghasilan) * 100 || 0;
        this.idir = this.ww.toFixed(2);
        //POINT CAPACITY
        // try {
        //   var pointCap = this.cp.filter((list) => this.idir >= list.range_min && this.idir <= list.range_max)
        //   this.pointCap = pointCap[0].point;
        //   this.pointCapId = pointCap[0].id;
        //   console.log('maksAngusuranDiambil ', ' = (' + this.dataCapa.total_penghasilan + '*' + this.params.max_angs_diambil + '/100) -' + this.totPengeluaran)
        //   console.log('dsr ', '= ((' + this.angsuranTempatlain + '+' + this.dataCapa.angs_diambil + ')/' + this.dataCapa.total_penghasilan + ')*100')
        //   console.log('idir ', '= ((' + this.dataCapa.angs_diambil + '+' + this.totPengeluaran + ')/' + this.dataCapa.total_penghasilan + ')*100')
        // } catch (error) {
        //   console.log(error)
        // }

        try {
          this.pointCap = this.detList.scoring.capa[0].point;
        } catch (error) {
          console.log(error)
          this.pointCap = 0;
        }
        // UPDATE LOCALSTORAGE
        this.dataProses = JSON.parse(localStorage.getItem('87gr3u4g37gf37yf34yf36y74f37437y4fghshdjf') || '');
        this.scoring = this.dataProses.scoring;
        // CAPA
        this.dataProses.scoring.capa[0].dsr = this.dsr;
        this.dataProses.scoring.capa[0].id = this.pointCapId;
        this.dataProses.scoring.capa[0].point = this.pointCap;
        this.dataProses.scoring.capa[0].idir = this.idir;
        this.dataProses.scoring.capa[0].max_angs = this.maksAngusuranDiambil;
        console.log('dataProses', this.dataProses)
        localStorage.setItem('87gr3u4g37gf37yf34yf36y74f37437y4fghshdjf', JSON.stringify(this.dataProses));
      } else {
        this.totPengeluaran = '-';
        this.maksAngusuranDiambil = '-';
      }
    } catch (error) {
      console.log(error)
      this.totPengeluaran = '-';
    }
  }

  kalkulasiTaksasi() {
    this.totalHargaTaksasi = this.coll_agunan.reduce((acc, o) => acc + parseFloat(o.taksasi), 0);
    this.totalHargaTaksasi = isNaN(this.totalHargaTaksasi) ? 0 : this.totalHargaTaksasi;
    console.log('totalHargaTaksasi', this.totalHargaTaksasi)
    this.kalkulasiLtv();
  }

  isNumber(value) {
    return Number.isNaN(value);
  }

  kalkulasiLtv() {
    console.log('this.pengajuan.submission_loan', this.pengajuan.submission_loan)
    console.log('totalHargaTaksasi', this.totalHargaTaksasi)
    var totalLtv = (this.pengajuan.submission_loan / this.totalHargaTaksasi) * 100;
    this.totalLtv = totalLtv.toFixed(2);
    // this.totalLtv = isNaN(this.totalLtv) ? 0 : this.totalLtv;
    // console.log('totalLtv',this.totalLtv)
    if (this.totalLtv >= this.pengajuan.ltv_value) {
      this.textMerah = true;
    } else {
      this.textMerah = false;
    }
    this.getPointColl();
  }

  getPointColl() {
    try {
      this.pointColl = this.detList.scoring.coll[0].point;
    } catch (error) {
      console.log(error);
      this.pointColl = 0;
    }
    // this.load = true;
    // let form = JSON.stringify({
    //   ltv: this.totalLtv,
    // });
    // this.api.post('surveyor/prospects/skoring/point/coll', form)
    //   .subscribe((data) => {
    //     this.pcl = data;
    //     if (this.pcl.error == 0) {
    //       this.load = false;
    //       this.pc = this.pcl.data;
    //       this.pointColl = this.pc.point;
    //       this.updateLSCol();
    //     } else {
    //       this.load = false;
    //       this.util.showToast(this.pcl.message, 'danger', 'bottom');
    //     }
    //   }, (error) => {
    //     this.load = false;
    //     console.log('error', error);
    //     this.api.errorCond(error)
    //   });
  }

  kalkulasiAgunan() {
    var a = this.scoring_cond.reduce((acc, o) => acc + parseFloat(o.point), 0) || 0;
    var b = this.scoring_ag.reduce((acc, o) => acc + parseFloat(o.agunan_unit_point), 0) || 0;
    var c = this.scoring_ag.reduce((acc, o) => acc + parseFloat(o.kepemilikan_aset_point), 0) || 0;
    // var d = this.scoring_ag.reduce((acc, o) => acc + (o.proses_aset_point !== null ? parseFloat(o.proses_aset_point) : 0), 0);
    var d = this.scoring_ag.reduce((acc, o) => {
      const point = parseFloat(o.proses_aset_point);
      return isNaN(point) ? acc : acc + point;
    }, 0);
    // var e = this.scoring_ag.reduce((acc, o) => acc + (o.domisili_flg_point !== null ? parseFloat(o.domisili_flg_point) : 0), 0);
    var e = this.scoring_ag.reduce((acc, o) => {
      const point = parseFloat(o.domisili_flg_point);
      return isNaN(point) ? acc : acc + point;
    }, 0);
    console.log('kalkulasiAgunan a', a);
    console.log('kalkulasiAgunan b', b);
    console.log('kalkulasiAgunan c', c);
    console.log('kalkulasiAgunan d', d);
    console.log('kalkulasiAgunan e', e);
    this.pointCond = parseInt(a) + parseInt(b) + parseInt(c) + parseInt(d) + parseInt(e);
    console.log('pointCond', this.pointCond)
  }

  getEditPengeluaran() {
    console.log('dataCapa', this.dataCapa)
    this.form = JSON.stringify({
      rumahTangga: this.dataCapa.pengeluaran_rumah_tangga || null,
      angsuranTempatLain: this.dataCapa.pengeluaran_angs_tempat_lain || null,
      biayaLainya: this.dataCapa.pengeluaran_biaya_lainnya || null,
      totalPengeluaran: this.dataCapa.total_pengeluaran || null,
    });
    // }
    var t = this.form;
    localStorage.setItem('5643ter34vt34t34t34rsdre4tse5tv45egv54t4', t);
  }

  updateLSCol() {
    // UPDATE LOCALSTORAGE
    this.dataProses = JSON.parse(localStorage.getItem('87gr3u4g37gf37yf34yf36y74f37437y4fghshdjf') || '');
    this.scoring = this.dataProses.scoring;
    // CAPA
    this.dataProses.scoring.coll[0].id = this.pc.id;
    this.dataProses.scoring.coll[0].ltv = this.totalLtv;
    this.dataProses.scoring.coll[0].point = this.pointColl;
    this.dataProses.scoring.coll[0].text = this.totalLtv;
    this.dataProses.scoring.coll[0].total_taksasi = this.totalHargaTaksasi;
    this.dataProses.scoring.coll[0].value = this.totalLtv;
    console.log('dataProses', this.dataProses)
    localStorage.setItem('87gr3u4g37gf37yf34yf36y74f37437y4fghshdjf', JSON.stringify(this.dataProses));
  }

  async tambah(id: any) {
    // const modal = await this.modalController.create({
    //   component: KolektorPenagihanAddComponent,
    //   cssClass: 'modalForm',
    //   componentProps: {
    //     id: id
    //   }
    // });
    // modal.onDidDismiss()
    //   .then((data: any) => {
    //     if (data.role === 'confirm') {
    //       this.detailData();
    //     }
    //   });
    // return await modal.present();
  }

  riwayat() {
    this.api.get('backoffice/kolektor/penagihan/riwayat-pemby/' + this.id + '/1')
      .subscribe((data) => {
        this.riwPem = data;
        if (this.riwPem.error == 0) {
          this.rw = this.riwPem.data;
        } else {
          this.load = false;
          this.util.showToast(this.riwPem.message, 'success', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  save() {
    this.load = true;
    var arrayData = [this.id];
    if (this.ids == '') {
      this.form = {
        contract_id: arrayData,
        assign_to: this.selct,
      };
    } else {
      this.form = {
        contract_id: JSON.parse(this.ids),
        assign_to: this.selct,
      };
    }
    this.api.post('backoffice/kolektor/penagihan/assignto', this.form)
      .subscribe((data) => {
        this.saving = data;
        if (this.saving.error == 0) {
          this.load = false;
          this.util.showToast(this.saving.message, 'success', 'bottom');
          this.api.dismissData('', 'confirm');
        } else {
          this.load = false;
          this.util.showToast(this.saving.message, 'danger', 'bottom');
        }
      }, (error) => {
        this.load = false;
        console.log('error', error);
        this.api.errorCond(error)
      });
  }

  handleSelection(selectedValue: string, model: any) {
    if (model == 'cabang') {
      this.cabang = selectedValue;
    } else if (model == 'usertype') {
      this.usertype = selectedValue;
    }
  }

  dismiss() {
    this.api.dismiss()
  }

  async preview(photo: any) {
    const modal = await this.modalController.create({
      component: PreviewPhotoLaporanComponent,
      cssClass: 'modalPreviewImage',
      // handle: false,
      backdropDismiss: false,
      // breakpoints: [0.5, 0.9, 1],
      // initialBreakpoint: 0.5,
      componentProps: {
        "id": photo,
      }
    });
    modal.onDidDismiss()
      .then((data) => {

      });
    return await modal.present();
  }



  mergingData() {
    try {
      console.log('gabunganAgunan', this.gabunganAgunan);

      this.agunan.forEach(item => {
        const { id, type_id, unit_id, unit_name, certificate_type_id, certificate_type_name, desc } = item;
        console.log('id', id)
        if (!this.idMap[id]) {
          this.idMap[id] = { id, values: [] };
        }
        this.idMap[id].values.push({ ...item });
        this.gabunganAgunan[id] = {
          id,
          type_id,
          unit_id,
          unit_name,
          desc,
          certificate_type_id,
          certificate_type_name,
          bpkb: [],
          stnk: [],
          shmshgb: [],
          other: [],
          document: []
        };
        console.log('')

        var bpkb = this.docAgunan.filter((item: any) => item.ref_id_2 === id && item.name === 'BPKB' || '')
        if (bpkb.length !== 0) {
          this.gabunganAgunan[id].bpkb.push(bpkb[0]);
        } else {
          delete this.gabunganAgunan[id]['bpkb']
        }
        console.log('bpkb', bpkb)

        var stnk = this.docAgunan.filter((item: any) => item.ref_id_2 === id && item.name === 'STNK' || '')
        if (stnk.length !== 0) {
          this.gabunganAgunan[id].stnk.push(stnk[0]);
        } else {
          delete this.gabunganAgunan[id]['stnk']
        }
        console.log('stnk', stnk)

        if (this.docAgunan !== '') {
          var shmshgb = this.docAgunan.filter((item: any) => item.ref_id_2 === id && item.name === 'SHM_SHGB' || '')
          if (shmshgb.length !== 0) {
            this.gabunganAgunan[id].shmshgb.push(shmshgb[0]);
          } else {
            delete this.gabunganAgunan[id]['shmshgb']
          }
          console.log('shmshgb', shmshgb)
        } else {
          delete this.gabunganAgunan[id]['shmshgb']
        }
        try {
          this.allfile = this.docAgunan.filter((item: any) => item.ref_id_2 === id || 0)
        } catch (error) {
          this.allfile = 0;
        }

        try {
          this.docx = this.docOtherAgunan[0].filter((item: any) => item.ref_id_2 === id || 0);
        } catch (error) {
          this.docx = 0;
        }
        console.log('this.allfile', this.allfile);
        console.log('this.docx', this.docx);
        if (this.docx !== 0 && this.allfile !== 0) {
          var combinedArray = this.docx.concat(this.allfile);
          this.gabunganAgunan[id].document.push(combinedArray)
        } else if (this.docx !== 0 && this.allfile == 0) {
          var combinedArray = this.docx;
          this.gabunganAgunan[id].document.push(combinedArray)
        } else if (this.docx == 0 && this.allfile !== 0) {
          var combinedArray = this.allfile;
          this.gabunganAgunan[id].document.push(combinedArray)
        } else {
          delete this.gabunganAgunan[id]['document']
        }
        console.log('combinedArray', combinedArray)
        // }else{
        //   delete this.gabunganAgunan[id]['document']
        // }

        if (this.docOtherAgunan !== '') {
          var other = this.docOtherAgunan[0].filter((item: any) => item.ref_id_2 === id || '');
          console.log('docOtherAgunan[0]', this.docOtherAgunan[0])
          console.log('other', other)
          if (other.length !== 0) {
            this.gabunganAgunan[id].other.push(other);
          } else {
            delete this.gabunganAgunan[id]['other']
          }
        } else {
          delete this.gabunganAgunan[id]['other']
        }
        console.log('other', other)
        console.log('')
      });
      // this.gabunganAgunan = Object.values(this.idMap);
      console.log('gabunganAgunan', this.gabunganAgunan)
      //==================================================================================================================

      this.gabunganAgunanx = Object.values(this.gabunganAgunan);

      console.log('gabunganAgunanx', this.gabunganAgunanx);
      console.log('agunan', this.agunan);

      console.log('docIdentitas', this.docIdentitas);
      console.log('docIdentitasCustom', this.docIdentitasCustom);
    } catch (error) {
      console.log('mergingData Error / Data DOkumenKosong', error)
      this.gabunganAgunanx = this.docAgunan;
    }

  }

  getBPKB(data: any, name: any) {
    var a = data.find((item: any) => item.name === name);
    return a;
  }
}